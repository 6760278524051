import React from 'react';

const TermsConditions = () => {
  return (
    <div className="policy-container">
      <h2>Terms & Conditions</h2>
      <p>
        These Terms and Conditions govern your use of the website and the services provided by Sri Radha Rani Seva Trust. By accessing or using our website, you agree to comply with these terms.
      </p>

      <h3>Donations</h3>
      <p>
        All donations made to Sri Radha Rani Seva Trust are voluntary and are used solely for providing free prasadam and supporting Braj Seva activities. Donations are non-refundable unless otherwise stated in our <a href="/cancellation-refund">Cancellation and Refund Policy</a>.
      </p>

      <h3>Payment Security</h3>
      <p>
        We ensure that your payment information is secure by using secure payment gateways. All payment transactions are encrypted to protect your financial data.
      </p>

      <h3>Use of Funds</h3>
      <p>
        The funds received will be used exclusively to provide food to Vaishnavas and support Braj Seva. We are committed to ensuring that donations are used responsibly for the pleasure of Radha Rani and Krishna.
      </p>

      <h3>Changes to Terms</h3>
      <p>
        We may update these Terms and Conditions from time to time. Any changes will be posted on this page, and your continued use of the website constitutes your acceptance of the new terms.
      </p>

      <h3>Contact Us</h3>
      <p>
        If you have any questions or concerns regarding these Terms and Conditions, please <a href="/contact-us">contact us</a>.
      </p>
    </div>
  );
};

export default TermsConditions;
