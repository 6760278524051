import React from 'react';

const CancellationRefund = () => {
  return (
    <div className="policy-container">
      <h2>Cancellation and Refund Policy</h2>
      <p>
        Sri Radha Rani Seva Trust highly appreciates your donations, which help us serve the Vaishnavas and perform Braj Seva. However, we have outlined our policies regarding cancellations and refunds below.
      </p>

      <h3>Cancellations</h3>
      <p>
        Once a donation is made, it cannot be canceled. Donations are considered final and will be used to support our projects as described on our website.
      </p>

      <h3>Refunds</h3>
      <p>
        Refunds are not provided for donations unless there is an exceptional circumstance, such as an error in the transaction or an overpayment. If you believe a refund is justified, please <a href="/contact-us">contact us</a> within 7 days of the donation.
      </p>

      <h3>Processing Time</h3>
      <p>
        Any approved refunds will be processed within 10 business days and will be made via the same method of payment as the original donation.
      </p>

      <h3>Contact Us</h3>
      <p>
        If you have any questions or concerns about cancellations or refunds, please <a href="/contact-us">contact us</a>.
      </p>
    </div>
  );
};

export default CancellationRefund;
