import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap";
// import transparentLogo from "../Media/transparentLogo.png";
import "./barstyles.css";

// Import Material Icons CSS
import '@fortawesome/fontawesome-free/css/all.min.css';

const NavLinks = [
  {
    label: "Home",
    path: "/",
    icon: "home",
    children: [],
  },
  {
    label: "Media",
    path: "/media/daily-darshan-images",
    icon: "photo_library",
    children: [],
  },
  {
    label: "Registrations",
    icon: "group_add",
    children: [
      {
        label: "Register for Festival Updates",
        path: "/register/festival-updates",
      },
      { label: "Register for IYF", path: "/register/iyf" },
      { label: "Register for IGF", path: "/register/igf" },
      {
        label: "Register to get added in Whatsapp Group",
        path: "/register/whatsapp-group",
      },
    ],
  },
  {
    path: "/donate/janmashtami",
    label: "Donate",
    icon: "favorite",
    children: [],
  }
  // ,
  // {
  //   label: "About",
  //   icon: "info",
  //   children: [
  //     { label: "ISKCON", path: "/about/iskcon" },
  //     { label: "Srila Prabhupada", path: "/about/srila-prabhupada" },
  //     { label: "Our Beliefs", path: "/about/our-beliefs" },
  //   ],
  // },
];

export default function Appbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openChildMenu, setOpenChildMenu] = useState(null);

  useEffect(() => {
    setIsMenuOpen(false);
    setOpenChildMenu(null);
  }, [location.pathname]);

  const handleNavigation = (path) => {
    setIsMenuOpen(false);
    setOpenChildMenu(null);
    navigate(path);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleChildMenu = (index) => {
    setOpenChildMenu(openChildMenu === index ? null : index);
  };

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <div className="appbar-container">
      <nav className="navbar navbar-expand-md navbar-light fixed-top bg-light p-3 appbar-navbar">
        <div className="container-fluid">
          {/* <img src={transparentLogo} alt="Logo" className="iskconLogo" /> */}
          <div>
            <a className="navbar-brand appbar-brand" href="#">
              Sri Radha Rani Seva Trust
            </a>
          </div>
          <button className="navbar-toggler" type="button" onClick={toggleMenu} style={{display:'none'}}>
            <span className="navbar-toggler-icon" />
          </button>
          {/* <div className={"collapse navbar-collapse" + (isMenuOpen ? " show" : "")}>
            <ul className="navbar-nav ml-auto appbar-nav">
              {NavLinks.map((link, index) => (
                <li className="nav-item dropdown appbar-nav-item" key={index}>
                  {link.children.length > 0 ? (
                    <>
                      <a
                        className={`nav-link dropdown-toggle appbar-nav-link ${isActive(link.path) ? 'active' : ''}`}
                        role="button"
                        onClick={() => toggleChildMenu(index)}
                      >
                        <i className="material-icons">{link.icon}</i>
                        <span className="ml-2">{link.label}</span>
                      </a>
                      <ul className={"dropdown-menu appbar-dropdown" + (openChildMenu === index ? " show" : " hide")}>
                        {link.children.map((child, childIndex) => (
                          <li key={childIndex}>
                            <a className={`dropdown-item appbar-dropdown-item ${isActive(child.path) ? 'active' : ''}`} onClick={() => handleNavigation(child.path)}>
                              {child.label}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </>
                  ) : (
                    <a className={`nav-link appbar-nav-link ${isActive(link.path) ? 'active' : ''}`} onClick={() => handleNavigation(link.path)}>
                      <i className="material-icons">{link.icon}</i>
                      <span className="ml-2">{link.label}</span>
                    </a>
                  )}
                </li>
              ))}
            </ul>
          </div> */}
        </div>
      </nav>
    </div>
  );
}
