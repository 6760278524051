import React from 'react';
import './styles.css'; // Add your custom styles here

const PrivacyPolicy = () => {
  return (
    <div className="policy-container">
      <h2>Privacy Policy</h2>
      <p>
        Sri Radha Rani Seva Trust respects your privacy and is committed to safeguarding any personal information you provide while using our website. This Privacy Policy outlines how we collect, use, and protect your personal data.
      </p>

      <h3>Information Collection</h3>
      <p>
        We may collect personal information, such as your name, email address, postal address, and payment details when you make a donation or contact us. This information is used solely for processing your donations and providing you with acknowledgments and receipts.
      </p>

      <h3>How We Use Your Information</h3>
      <p>
        The personal data collected is used to:
        <ul>
          <li>Process donations and provide receipts</li>
          <li>Communicate with you regarding your donations and our activities</li>
          <li>Comply with legal requirements</li>
        </ul>
      </p>

      <h3>Data Security</h3>
      <p>
        We use industry-standard measures to protect your personal information from unauthorized access, disclosure, or misuse. We will never share, sell, or rent your personal data to third parties.
      </p>

      <h3>Your Consent</h3>
      <p>
        By using our website and providing personal information, you consent to our collection and use of this data in accordance with this Privacy Policy.
      </p>

      <h3>Contact Us</h3>
      <p>
        If you have any questions about this Privacy Policy or how your personal information is handled, please <a href="/contact-us">contact us</a>.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
