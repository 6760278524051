import React from "react";
import HeroSection from "./HeroSection/HeroSection";
import CarouselComponent from "./HeroSection/Carousel";

export default function Homepage(){
    const imageSource = "https://iskcon-additionals.s3.ap-south-1.amazonaws.com/radhakrishna.jpeg";
    return(
        <>
            <HeroSection/>
            <CarouselComponent/>
        </>
    )
}