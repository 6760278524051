import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

export default function CarouselComponent() {
  return (
    <>
      <div style={{ marginTop: "4rem" }}>
        <center>
          <Carousel
            infiniteLoop={true}
            autoPlay={true}
            showStatus={false}
            showIndicators={false}
            interval={1200}
            width={"90%"}
          >
            <div>
              <img
                src={
                  "https://iskconcoimbatore.com/_next/image?url=%2Fimg%2Fbanners%2Fdonate%2Fvaishnav-bhojan%2F3.jpg&w=640&q=100"
                }
              />
            </div>
            <div>
              <img
                src={
                  "https://iskconcoimbatore.com/_next/image?url=%2Fimg%2Fbanners%2Fdonate%2Fvaishnav-bhojan%2F1.jpg&w=640&q=100"
                }
              />
            </div>
            <div>
              <img
                src={
                  "https://iskconcoimbatore.com/_next/image?url=%2Fimg%2Fbanners%2Fdonate%2Fvaishnav-bhojan%2F2.jpg&w=640&q=100"
                }
              />
            </div>
          </Carousel>

          <p
            style={{ margin: "8px" }}
            data-aos="fade-right"
            data-aos-delay="900"
          >
            Vaishnav Bhojan - What is the significance of feeding a Vaishnava
            with Prasadam?
          </p>
          <p
            style={{ margin: "8px" }}
            data-aos="fade-right"
            data-aos-delay="900"
          >
            Feeding a Vaishnava is an act of devotion and service that allows
            one to directly please the Supreme Lord. It is considered a powerful
            means to elevate oneself spiritually and receive the Lord's
            blessings.
          </p>
        </center>
      </div>
    </>
  );
}
