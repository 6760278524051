import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HeroSection from "./Components/HeroSection/HeroSection";
import Homepage from "./Components/Homepage";
import PrivacyPolicy from "./PaymentLinks/PrivacyPolicy";
import TermsConditions from "./PaymentLinks/TermsConditions";
import CancellationRefund from "./PaymentLinks/CancellationRefund";
import ShippingDelivery from "./PaymentLinks/ShippingDelivery";
import ContactUs from "./PaymentLinks/ContactUs";
import Appbar from "./Components/HeroSection/Appbar/Appbar";

function App() {
  return (
    <>
      <Router>
        <Appbar/>
        <Routes>
          <Route exact path="/" element={<Homepage />} />






          {/* PAYMENT LINKS- EXTERNAL */}
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/tnc" element={<TermsConditions />} />
          <Route
            exact
            path="/cancellation-refund"
            element={<CancellationRefund />}
          />
          <Route
            exact
            path="/shipping-delivery"
            element={<ShippingDelivery />}
          />
          <Route exact path="/contact-us" element={<ContactUs />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
