import React from 'react';

const ContactUs = () => {
  return (
    <div className="policy-container">
      <h2>Contact Us</h2>
      <p>If you have any questions, concerns, or need assistance, feel free to reach out to us using the information below:</p>
      <ul>
        <li><strong>Email:</strong> dasachintyabalai@gmail.com </li>
        <li><strong>Phone:</strong> +91 95695 31059 </li>
        <li><strong>Address:</strong> Radha Kund, Mathura, Uttar Pradesh, India</li>
      </ul>
      <p>We are here to assist you with any queries regarding donations, services, or our Braj Seva initiatives.</p>
    </div>
  );
};

export default ContactUs;

