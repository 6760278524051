import React from "react";

const ShippingDelivery = () => {
  return (
    <div className="policy-container">
      <h2>Shipping and Delivery Policy</h2>
      <p>
        As Sri Radha Rani Seva Trust primarily focuses on providing free
        prasadam and conducting Braj Seva, we do not typically offer physical
        products. However, in the event of special campaigns or programs where
        physical goods are involved, we provide the following shipping and
        delivery terms.
      </p>

      <h3>Digital Receipts</h3>
      <p>
        All donations will receive a digital receipt via email, confirming the
        donation. Please ensure that your email address is accurate when making
        a donation.
      </p>

      <h3>Shipping of Physical Items</h3>
      <p>Shipping is not applicable for business. </p>

      <h3>Contact Us</h3>
      <p>
        For any questions regarding shipping or delivery, please{" "}
        <a href="/contact-us">contact us</a>.
      </p>
    </div>
  );
};

export default ShippingDelivery;
